var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0"
  }, [_vm.loading ? _c('v-row', _vm._l(4, function (i) {
    return _c('v-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-skeleton-loader', {
      attrs: {
        "type": "list-item-two-line"
      }
    })], 1);
  }), 1) : _c('DatatableWrapper', {
    attrs: {
      "loading": _vm.loading,
      "pagination": _vm.pagination
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.transactionList,
      "hide-default-footer": "",
      "mobile-breakpoint": "0",
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "item.date",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.getFormattedDate(item.created_at)) + " ")])];
      }
    }, {
      key: "item.action",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          class: _vm.$options.PAYMENT_ACTION[item.action].color + '--text'
        }, [_vm._v(" " + _vm._s(_vm.$options.PAYMENT_ACTION[item.action].title) + " ")])];
      }
    }, {
      key: "item.amount",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.action_date ? _c('div', [_c('b', [_vm._v("-")])]) : _c('div', [_c('b', [_vm._v(_vm._s(item.currency) + " " + _vm._s(item.amount))])])];
      }
    }, {
      key: "item.info",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.receipt_url ? _c('a', {
          staticClass: "primary--text cursor-pointer font14",
          attrs: {
            "href": item.receipt_url,
            "target": "_blank"
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "primary",
            "left": "",
            "small": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-eye')
          }
        }), _vm._v(" View invoice ")], 1) : _vm._e(), item.action_date ? _c('div', [_vm._v(" " + _vm._s(item.action === "subscription_payment_failed" ? "Retry on" : "Cancelled on") + ": " + _vm._s(item.action_date) + " ")]) : _vm._e(), !item.receipt_url && !item.action_date ? _c('div', [_vm._v("-")]) : _vm._e()];
      }
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-row', [_c('v-col', {
          staticClass: "no-data-found",
          attrs: {
            "cols": "12"
          }
        }, [_c('no-data', {
          attrs: {
            "first-text": "No Transaction History Yet",
            "second-text": "<p>Want to see some action here? Subscribe and this quiet spot will start filling up with your transaction details. Give it a try!</p>"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer.page-text",
      fn: function fn() {
        return [_c('div', [_c('v-btn', {
          staticClass: "text-transform-none",
          attrs: {
            "color": "darkGrey",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.fetchTransactionHistory();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": _vm.$vuetify.theme.dark ? '' : 'grey',
            "left": ""
          },
          domProps: {
            "textContent": _vm._s('mdi-reload')
          }
        }), _vm._v(" Reload ")], 1), _vm._v(" Total: "), _c('b', [_vm._v(_vm._s(_vm.totalTransactionCount))])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }