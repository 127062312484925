<template>
  <v-row>
    <v-col cols="12">
      <v-card flat>
        <v-card-text class="pa-0">
          <v-row v-if="loading">
            <v-col v-for="i in 4" :key="i" cols="12">
              <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
            </v-col>
          </v-row>
          <DatatableWrapper v-else :loading="loading" :pagination="pagination">
            <v-data-table
              :headers="headers"
              :items="transactionList"
              hide-default-footer
              mobile-breakpoint="0"
              :loading="loading"
            >
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.date="{ item }">
                <div>
                  {{ getFormattedDate(item.created_at) }}
                </div>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.action="{ item }">
                <div
                  :class="$options.PAYMENT_ACTION[item.action].color + '--text'"
                >
                  {{ $options.PAYMENT_ACTION[item.action].title }}
                </div>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.amount="{ item }">
                <div v-if="item.action_date">
                  <b>-</b>
                </div>
                <div v-else>
                  <b>{{ item.currency }} {{ item.amount }}</b>
                </div>
              </template>
              <!--            <template #item.payment_status="{item}">-->
              <!--              <div>-->
              <!--                {{ item.payment_status }}-->
              <!--              </div>-->
              <!--            </template>-->
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #item.info="{ item }">
                <a
                  v-if="item.receipt_url"
                  :href="item.receipt_url"
                  class="primary--text cursor-pointer font14"
                  target="_blank"
                >
                  <v-icon
                    color="primary"
                    left
                    small
                    v-text="'mdi-eye'"
                  ></v-icon>
                  View invoice
                </a>
                <div v-if="item.action_date">
                  {{
                    item.action === "subscription_payment_failed"
                      ? "Retry on"
                      : "Cancelled on"
                  }}: {{ item.action_date }}
                </div>
                <div v-if="!item.receipt_url && !item.action_date">-</div>
              </template>
              <template #no-data>
                <v-row>
                  <v-col class="no-data-found" cols="12">
                    <no-data
                      first-text="No Transaction History Yet"
                      second-text="<p>Want to see some action here? Subscribe and this quiet spot will start filling up with your transaction details. Give it a try!</p>"
                    ></no-data>
                  </v-col>
                </v-row>
              </template>
              <!-- eslint-disable-next-line vue/valid-v-slot -->
              <template #footer.page-text>
                <div>
                  <v-btn
                    class="text-transform-none"
                    color="darkGrey"
                    text
                    @click="fetchTransactionHistory()"
                  >
                    <v-icon
                      :color="$vuetify.theme.dark ? '' : 'grey'"
                      left
                      v-text="'mdi-reload'"
                    >
                    </v-icon>
                    Reload
                  </v-btn>
                  Total: <b>{{ totalTransactionCount }}</b>
                </div>
              </template>
            </v-data-table>
          </DatatableWrapper>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { GET_TRANSACTION_HISTORY } from "@/store/team/team.module";
import { mapGetters, mapMutations } from "vuex";
import dayjs from "dayjs";
import DatatableWrapper from "@/view/components/App/DatatableWrapper";

export default {
  name: "transaction-history",
  components: { DatatableWrapper },
  PAYMENT_ACTION: {
    subscription_created: {
      title: "Subscription created",
      color: "primary",
    },
    subscription_updated: {
      title: "Subscription updated",
      color: "primary",
    },
    subscription_cancelled: {
      title: "Subscription cancelled",
      color: "error",
    },
    subscription_payment_succeeded: {
      title: "Paid",
      color: "success",
    },
    subscription_payment_failed: {
      title: "Payment failed",
      color: "error",
    },
    subscription_payment_refunded: {
      title: "Payment refunded",
      color: "warning",
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: "Date",
          align: "start",
          width: 150,
          sortable: false,
          value: "date",
        },
        {
          text: "Action",
          align: "start",
          width: 150,
          sortable: false,
          value: "action",
        },
        {
          text: "Amount",
          align: "start",
          width: 100,
          sortable: false,
          value: "amount",
        },
        // {
        //   text: "Payment Status",
        //   align: "start",
        //   width: 100,
        //   sortable: false,
        //   value: "payment_status",
        // },
        {
          text: "Invoice",
          align: "start",
          width: 150,
          sortable: false,
          value: "info",
        },
      ],
      pagination: {
        page: this.toNumber(this.$route.query.page),
        itemsPerPage: 10,
        totalItems: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      transactionList: "getTransactionList",
      totalTransactionList: "getTotalTransactionList",
      totalTransactionCount: "getTotalTransactionCount",
    }),
  },
  watch: {
    totalTransactionCount(val) {
      this.pagination.totalItems = val;
    },
    "pagination.page"(val, oldVal) {
      let offset = (val - 1) * 10;
      let limit = val * 10;
      // checked if it is next or previous
      if (val > oldVal) {
        //  if next and all the data are already fetched
        if (this.totalTransactionList.length > oldVal * 10) {
          this.setPreviousTransactionData(
            this.totalTransactionList.slice(offset, limit)
          );
        } else {
          // if it is first time, get last id and hit api
          const lastId =
            this.transactionList[this.transactionList.length - 1].id;
          this.fetchTransactionHistory(lastId);
        }
      } else {
        // if it is previous then show already fetched data
        this.setPreviousTransactionData(
          this.totalTransactionList.slice(offset, limit)
        );
      }
    },
  },
  created() {
    this.fetchTransactionHistory();
  },
  methods: {
    ...mapMutations({
      setPreviousTransactionData: "setPreviousTransactionData",
    }),
    getFormattedDate(date) {
      if (!Number.isInteger(date)) {
        return dayjs(date).format("MMM, DD YYYY");
      }
      return dayjs(date * 1000).format("MMM, DD YYYY");
    },
    fetchTransactionHistory(lastId) {
      if (!lastId) {
        this.page = 1;
      }
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_TRANSACTION_HISTORY, { lastId: lastId || "" })
        .then(() => {
          this.loading = false;
          this.pagination.totalItems = this.totalTransactionCount;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message);
        });
    },
  },
};
</script>
